import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import double_triangle from "../../images/pyramid/double_triangle.svg";

const Pyramid4 = () =>(
    <section className="pyramid-5">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>Was ist das Geheimnis, das THE PYRAMID so effektiv macht?</h2>
                    <p>Dank THE PYRAMID macht es tausenden von Frauen wieder Spaß zu trainieren. Die erstaunliche Wirkung des Trainingskonzepts liegt in der ständigen Muskelstimulation. Es ist völlig anders als die meisten anderen Workouts, bei denen die Muskeln während der Hälfte der Zeit "ausgeschaltet" sind.</p>
                </Col>
            </Row>
            <Row className="d-md-none grey-bg align-items-center">
                <Col xs="4">
                    <img src={double_triangle} className="img-fluid" alt="" />
                </Col>
                <Col xs="8">
                    <p>Der Schlüssel zur dauerhaften Aktivierung deiner Muskeln sind das spezielle Timing und die Kombination von Übungen.</p>
                </Col>
            </Row>            
            <Row className="d-none d-md-block">
                <Col md={{size: 10, offset: 1}}>
                    <p>Der Schlüssel zur dauerhaften Aktivierung deiner Muskeln sind das spezielle Timing und die Kombination von Übungen. THE PYRAMID stärkt den ganzen Körper und boostet die Fettverbrennung - das Ergebnis ist schon nach kürzester Zeit zu sehen.</p>
                </Col>
            </Row>
            <Row className="d-md-none">
                <Col md={{size: 10, offset: 1}}>
                    <p>THE PYRAMID stärkt den ganzen Körper und boostet die Fettverbrennung - das Ergebnis ist schon nach kürzester Zeit zu sehen.</p>
                </Col>
            </Row>                
        </Container>
    </section>
);

export default Pyramid4;
