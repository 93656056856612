import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Img from "gatsby-image";

const Pyramid7 = (props) => (
    <section className="pyramid-7">
        <Container>
            <Row className="align-items-center">
                <Col xs={{size: 12, order: 2}} md={{size: 5, offset: 1, order: 1}}>
                    <div className="text-center d-md-none">
                        <a href={props.url} className="btn btn-primary mb-3">Jetzt kostenlos downloaden</a>
                    </div>
                    < Img fluid={props.phones} />
                </Col>
                <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}} className="text-center grey-bg">
                    <h2>Bist du bereit für das unglaublichste 12-Minuten-Training deines Lebens?</h2>
                    <p>Werde Teil einer Community von mehr als 1 Million aktiver Frauen, die tausende Kalorien verbrennen und dank THE PYRAMID auf dem Weg zu ihrem Traumkörper sind. Downloade die App <strong>„Diet & Training by Ann“</strong> heute KOSTENLOS!</p>
                    <a href={props.url} className="btn btn-primary">Jetzt kostenlos downloaden</a>
                    <h2 className="mt-5">Deine persönliche Trainerin Anna Lewandowska - Europas Online-Fitness-Queen</h2>
                    <p>Mehr als 2,5 Millionen Follower, Gewinnerin vieler europäischer und weltweiter Karate-Meisterschaften und Ehefrau von Fußballstar Robert Lewandowski, einem der besten Stürmer der Welt.</p>
                </Col>
            </Row>
        </Container>
    </section>
);
    
export default Pyramid7;
