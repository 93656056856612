import React  from 'react';
import { Container, Nav, NavItem } from 'reactstrap';

const Footer = () =>(
    <section className="footer">
        <Container>
            <Nav className="justify-content-center">
                <NavItem>
                    <a
                        className="nav-link"
                        href="https://trainingbyann.com/contact"
                    >
                        Contact Support
                    </a>
                </NavItem>
                <NavItem>
                    <a
                        className="nav-link"
                        href="https://trainingbyann.com/privacy-policy"
                    >
                        Privacy Policy
                    </a>
                </NavItem>
                <NavItem>
                    <a
                        className="nav-link"
                        href="https://trainingbyann.com/terms-of-service"
                    >
                        Terms of service
                    </a>
                </NavItem>
            </Nav>
        </Container>
    </section>
);

export default Footer;
