import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

const Pyramid2 = () =>(
    <section className="pyramid-2">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>Dein neues Trainingskonzept für bessere Fettverbrennung und mehr Energie</h2>
                    <p>THE PYRAMID	ist eine revolutionäre, neue Art von progressivem Training. Erstaunlich, aber in <strong>nur 12 Minuten</strong> bringt es die gleichen Ergebnisse wie ein 45-Minuten-Training im Fitnessstudio!</p>
                    <p>Durch die Kombination verschiedener spezieller Übungen von wechselnder Dauer bringt es deinen Körper auf Trab und maximiert so die Fettverbrennung und den Muskelaufbau. Und das Beste von allem: Schon nach 7 Tagen sieht man die ersten Ergebnisse!</p>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Pyramid2;
