import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

const Pyramid1 = (props) => (
        <section className="pyramid-1" id="piramida1">
            <Container>
                <Row>
                    <Col xs={{size: 12, offset: 0}} md={{size: 10, offset: 1}}>
                        <h2>192 Kalorien in 12 Minuten verbrennen – einfach und ohne Fitnessstudio</h2>
                        <p>Verschwende keine Zeit mehr mit langweiligen und zeitraubenden Workouts und spare dir das Geld für teure Fitnessclubs mit dem neuen Trainingskonzept, das schon hunderttausende Frauen überzeugt hat.</p>
                        <br />
                        <h2>Du hast nicht viel Zeit? Bist genervt von langweiligen Trainingseinheiten? Dann ist diese neue Art von Fitnesstraining genau das Richtige für dich…</h2>
                        <p>Wie sieht dein Traumkörper aus? Ein flacher Bauch, ein wohlgeformter Po, straffe Arme und Beine? Seien wir ehrlich, normalerweise kannst du das nur erreichen, wenn du viele, viele Stunden im Fitnessstudio verbringst. Und wer hat heutzutage noch Zeit dafür?</p>
                        <p>Mit THE PYRAMID kommst du einfach und schnell zu deiner Traumfigur. Alles, was du dafür brauchst, sind 12 Minuten pro Tag…</p>
    
                        <div className="text-center mt-5">
                            <a href={props.url} className="btn btn-primary">Jetzt ausprobieren</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
);

export default Pyramid1;
