import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import fire from "../../images/pyramid/fire-outline.svg";
import support from "../../images/pyramid/support-outline.svg";
import map from "../../images/pyramid/map-outline.svg";
import triangle from "../../images/pyramid/triangle.svg";
import scrollToElement from '../../helpers/scrollToElement';

const Pyramid4 = (props) =>(
    <section className="pyramid-6">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>Und noch mehr Vorteile von THE PYRAMID…</h2>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}}  md={{size: 1, offset: 1}}>
                    <img src={fire} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>MAXIMALE KALORIENVERBRENNUNG</strong><br/>
                    Dieses Spezialtraining basiert auf vielfach erprobten Übungen. Durch die Aktivierung aller Muskelgruppen verbrennt der Körper ein Maximum an Kalorien.</p>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}} md={{size: 1, offset: 1}}>
                    <img src={support} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>MOTIVIERT UND GUT GELAUNT TRAINIEREN</strong><br/>
                    Schnelle, sofort sichtbare Ergebnisse zaubern ein Lächeln auf dein Gesicht und motivieren dich, dranzubleiben! Jeden Tag gibt es neue, spannende Workouts – so verlierst du nie die Motivation!</p>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}} md={{size: 1, offset: 1}}>
                    <img src={map} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>ÜBERALL UND JEDERZEIT TRAINIEREN</strong><br/>
                    Trainiere dann, wenn DU Lust und Zeit hast! Du willst zu Hause, im Park oder im Urlaub trainieren? Kein Problem – auch ohne Internet!</p>
                </Col>
            </Row>

            <Row className="d-md-none mb-5">
                <Col>
                    <div className="line mt-5">
                        <span>
                            <div
                                className="round-button"
                                onClick={() =>
                                    scrollToElement(
                                        document.getElementById('piramida2')
                                    )
                                }
                            >
                                <img src={triangle} alt="" />
                            </div>         
                        </span>
                    </div>
                </Col>
            </Row>

            <Row id="piramida2">
                <Col md={{size: 10, offset: 1}}>
               
                    <p><strong>MAXIMALE KALORIENVERBRENNUNG</strong><br/>Dieses Spezialtraining basiert auf vielfach erprobten Übungen. Durch die Aktivierung aller Muskelgruppen verbrennt der Körper ein Maximum an Kalorien.</p>

                    <h2>Vergiss, was du immer gehört hast - Workouts müssen nicht lange und kompliziert sein…</h2>
                    <p>Dein Weg zu einer schlanken Figur, mehr Energie und Selbstvertrauen führt nicht unbedingt durch ewige Workouts im Fitnessstudio.</p>
                    <p>THE PYRAMID ist einfach, aber unglaublich effektiv. Es ist ein genau definierter, leicht zu befolgender Plan - mit seiner Hilfe wirst du deinen Körper lieben!</p>

                    <div className="line mt-5 d-none d-md-block">
                        <span className="d-inline">
                            <a href={props.url} className="btn btn-primary">Jetzt installieren</a>
                        </span>
                    </div>

                    <a href={props.url} className="btn btn-primary d-md-none mt-5">Jetzt installieren</a>
                </Col>
            </Row>
                          
        </Container>
    </section>
);

export default Pyramid4;
